import { MainContainer, ContentContainer } from "./style";
import { AiOutlineCloudDownload } from "react-icons/ai";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000"; // URL da API vinda do .env

const BoletoContainer = ({ num, valor, date, link }) => {
  
  const ViewBoleto = async () => {
    if (!link) {
      console.error("⚠️ Link do boleto não disponível.");
      return;
    }

    try {
      const response = await fetch(`${API_URL}/ver-boleto`, { // Uso da URL dinâmica
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ link }),
      });

      if (!response.ok) {
        throw new Error("Erro ao abrir o boleto.");
      }

      const html = await response.text();
      const newWindow = window.open();

      if (newWindow) {
        newWindow.document.open();
        newWindow.document.write(html);
        newWindow.document.close();
      } else {
        console.error("⚠️ O navegador bloqueou a abertura da janela.");
      }
    } catch (error) {
      console.error("Erro ao carregar boleto:", error);
    }
  };

  const finaldate = date ? date.slice(0, 10).split("-").reverse().join("/") : "Data Indisponível";

  return (
    <MainContainer>
      <ContentContainer>
        <p>{num}</p>
      </ContentContainer>

      <ContentContainer>
        <p>{valor.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</p>
      </ContentContainer>

      <ContentContainer>
        <p>{finaldate}</p>
      </ContentContainer>

      <ContentContainer>
        <AiOutlineCloudDownload size="1.4rem" onClick={ViewBoleto} />
      </ContentContainer>
    </MainContainer>
  );
};

export default BoletoContainer;
