import {
  MainContainer,
  Descriptions,
  NewInput,
  ImageContainer,
  LogoImg,
  MyImage,
  HeaderContainer,
  MyimageContainer,
  BoletosContainer,
  NewForm,
  Messages,
  Saudacoes,
  HeaderColum,
  Footer,
  ContentFooter,
  ContentContainer,
} from "./style.js";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useState, useCallback } from "react";
import Button from "@material-ui/core/Button";
import LoadingGif from "./imagens/LoadingGif.gif";
import img from "./imagens/img00.svg";
import logo from "./imagens/logo.png";
import BoletoContainer from "./components/boletoContainer";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillTwitterCircle,
} from "react-icons/ai";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000"; // URL dinâmica do backend

function App() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [erro, setErro] = useState("");

  const useYupValidationResolver = (validationSchema) =>
    useCallback(
      async (data) => {
        try {
          const values = await validationSchema.validate(data, {
            abortEarly: false,
          });
          return { values, errors: {} };
        } catch (errors) {
          setErro("Deve conter entre 11 e 14 dígitos numéricos");
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors, currentError) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? "validation",
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [validationSchema]
    );

  const schema = yup.object().shape({
    cnpj: yup
      .string()
      .required("Deve conter entre 11 e 14 dígitos numéricos")
      .matches(/^[0-9]+$/, "Apenas Números")
      .min(11, "Deve conter entre 11 e 14 dígitos numéricos"),
  });

  const { register, handleSubmit } = useForm({
    resolver: useYupValidationResolver(schema),
  });

  const buscarBoletos = async (data) => {
    setLoading(true);
    setErro("");

    try {
      const response = await fetch(`${API_URL}/busca`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cnpj: data.cnpj }),
      });

      if (!response.ok) {
        throw new Error("Erro ao buscar boletos. Tente novamente.");
      }

      const result = await response.json();

      if (result.length === 0) {
        setErro("Nenhum boleto encontrado.");
      }

      setData(result);
    } catch (error) {
      setErro(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainContainer>
      <ImageContainer>
        <HeaderContainer>
          <a href="https://personcloud.com.br">
            <LogoImg src={logo} alt="Logo" />
          </a>
        </HeaderContainer>
        <MyimageContainer>
          <MyImage src={img} alt="Imagem" />
        </MyimageContainer>
      </ImageContainer>

      <ContentContainer>
        <BoletosContainer>
          <NewForm onSubmit={handleSubmit(buscarBoletos)}>
            <Descriptions>Baixar Boleto</Descriptions>
            <NewInput
              required
              label="CPF/CNPJ"
              name="cnpj"
              type="text"
              {...register("cnpj")}
            />

            <Button type="submit" variant="contained" color="primary">
              {!loading ? (
                "Buscar Boleto"
              ) : (
                <img alt="Carregando..." src={LoadingGif} />
              )}
            </Button>
          </NewForm>

          <Messages>
            {erro && <p>{erro}</p>}
            <br />
            <p>
              Insira o seu CPF ou CNPJ cadastrado em nosso sistema e aguarde até
              10 segundos para localizarmos o seu boleto em aberto.
            </p>
            <br />
          </Messages>

          {data.length > 0 && (
            <Saudacoes>
              <p>Olá, {data[0]["Sacado"]}.</p>
            </Saudacoes>
          )}

          {data.length > 0 && (
            <HeaderColum>
              <p>Nº Doc</p>
              <p>Valor</p>
              <p>Vencimento</p>
              <p>Baixar</p>
            </HeaderColum>
          )}

          {data.map(({ NumDoc, Valor, Data, link }, key) => (
            <BoletoContainer
              key={key}
              num={NumDoc}
              valor={Valor}
              date={Data}
              link={link}
            />
          ))}
        </BoletosContainer>

        <Footer>
          <ContentFooter>
            <a
              target="_blank"
              href="https://www.facebook.com/personclouderp"
              rel="noreferrer"
            >
              <AiFillFacebook size="1rem" />
              <p>Facebook</p>
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/personclouderp"
              rel="noreferrer"
            >
              <AiFillInstagram size="1rem" />
              <p>Instagram</p>
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/personclouderp"
              rel="noreferrer"
            >
              <AiFillLinkedin size="1rem" />
              <p>LinkedIn</p>
            </a>
            <a
              target="_blank"
              href="https://twitter.com/personclouderp"
              rel="noreferrer"
            >
              <AiFillTwitterCircle size="1rem" />
              <p>Twitter</p>
            </a>
          </ContentFooter>
          <br />
          {`© ${new Date().getFullYear()} ERP PERSON CLOUD.`} <br />
          {`Todos os direitos reservados a`} <br />
          <a target="_blank" href="https://inforperson.com.br" rel="noreferrer">
            INFORPERSON CONSULTORIA
          </a>
        </Footer>
      </ContentContainer>
    </MainContainer>
  );
}

export default App;
